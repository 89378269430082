import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationHeader } from '@app/models/notification-header.model';

@Injectable({
    providedIn: 'root',
})
export class NotificationObservable {
    private dataSubject = new BehaviorSubject<NotificationHeader | null>(null);
    data$ = this.dataSubject.asObservable();

    updateData(data: NotificationHeader | null): void {
        this.dataSubject.next(data);
    }
}
